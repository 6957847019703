<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Rekap Tindakan Medis Perbulan</b> Klinik IONA
      </div>
      <!-- <b-button
        squared
        variant="success"
        @click="$router.push('/medicine-packages/add')"
        v-if="btn"
      >Tambah</b-button> -->
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <b-tabs
              active-nav-item-class="bg-success text-white"
            >
              <b-tab title="Perawat" active>
                <TableNurse />
              </b-tab>
              <b-tab title="Dokter">
                <TableDoctor />
              </b-tab>
            </b-tabs> 
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import TableNurse from '@/component/action-reports/Table.vue'
import TableDoctor from '@/component/action-reports/TableDoctor.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: "List",

  components: {
    Card,
    TableNurse,
    TableDoctor,
  },

  data() {
    return {
      // Filter
      filter: {
        name: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "action_name",
          label: "Nama Tindakan",
          sortable: true,
        },
        {
          key: "medicines",
          label: "Obat dan Alkes",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
    }
  },

  methods: {

    

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekap Tindakan Medis Perbulan" }])
    // Get Data
  
  },

}

</script>
